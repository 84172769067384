import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";
import { FiPhoneCall } from "react-icons/fi";
import { ImWhatsapp } from "react-icons/im";
SwiperCore.use([Navigation]);

const Projects = () => {
  return (
    <>
      <section className="tp-project-area white-bg position-relative">
        <div className="tp-project-shape"></div>
        <div
          className="tp-project-wrapper grey-bg pt-120 wow fadeInUp"
          data-wow-delay=".2s"
        >
          <div className="section-title-wrapper text-center mb-55 grey-bg">
            <h5 className="tp-section-subtitle common-yellow-shape section__sm__title mb-20">
              Some Completed Project
            </h5>
            <h2 className="tp-section-title mb-20">
              Every Project is Different <br />
              Every Client special
            </h2>
          </div>
          <div className="tp-project-active swiper-container">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              className="swiper-wrapper"
              autoplay={{ delay: 6000 }}
              breakpoints={{
                550: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 3,
                },
                1600: {
                  slidesPerView: 4,
                },
              }}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className="col-lg-12 col-md-12">
                  <div className="tp-project z-index mb-30">
                    <div className="tp-project-img">
                      <img
                        src={`assets/img/project/project-tab-1.jpg`}
                        className="img-fluid"
                        alt="img not found"
                      />
                    </div>
                    <div className="tp-project-text">
                      <div className="tp-project-text-content">
                        <span className="tp-project-subtitle">
                          Painting & cleaning service
                        </span>
                        <h4 className="tp-project-title">
                          <a href="tel:9500466996">
                            <i className="mr-10">
                              <FiPhoneCall />
                            </i>
                            95004 66996
                          </a>
                        </h4>
                      </div>
                      <div className="tp-project-text-icon">
                        <a href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks.">
                          <i className="text-white">
                            <ImWhatsapp />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-12 col-md-12">
                  <div className="tp-project z-index mb-30">
                    <div className="tp-project-img">
                      <img
                        src={`assets/img/project/project-tab-2.jpg`}
                        className="img-fluid"
                        alt="img not found"
                      />
                    </div>
                    <div className="tp-project-text">
                      <div className="tp-project-text-content">
                        <span className="tp-project-subtitle">
                          Painting & cleaning service
                        </span>
                        <h4 className="tp-project-title">
                          <a href="tel:9500466996">
                            <i className="mr-10">
                              <FiPhoneCall />
                            </i>
                            95004 66996
                          </a>
                        </h4>
                      </div>
                      <div className="tp-project-text-icon">
                        <a href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks.">
                          <i className="text-white">
                            <ImWhatsapp />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-12 col-md-12">
                  <div className="tp-project z-index mb-30">
                    <div className="tp-project-img">
                      <img
                        src={`assets/img/project/project-tab-3.jpg`}
                        className="img-fluid"
                        alt="img not found"
                      />
                    </div>
                    <div className="tp-project-text">
                      <div className="tp-project-text-content">
                        <span className="tp-project-subtitle">
                          Painting & cleaning service
                        </span>
                        <h4 className="tp-project-title">
                          <a href="tel:9500466996">
                            <i className="mr-10">
                              <FiPhoneCall />
                            </i>
                            95004 66996
                          </a>
                        </h4>
                      </div>
                      <div className="tp-project-text-icon">
                        <a href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks.">
                          <i className="text-white">
                            <ImWhatsapp />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-12 col-md-12">
                  <div className="tp-project z-index mb-30">
                    <div className="tp-project-img">
                      <img
                        src={`assets/img/project/project-tab-4.jpg`}
                        className="img-fluid"
                        alt="img not found"
                      />
                    </div>
                    <div className="tp-project-text">
                      <div className="tp-project-text-content">
                        <span className="tp-project-subtitle">
                          Painting & cleaning service
                        </span>
                        <h4 className="tp-project-title">
                          <a href="tel:9500466996">
                            <i className="mr-10">
                              <FiPhoneCall />
                            </i>
                            95004 66996
                          </a>
                        </h4>
                      </div>
                      <div className="tp-project-text-icon">
                        <a href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks.">
                          <i className="text-white">
                            <ImWhatsapp />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-12 col-md-12">
                  <div className="tp-project z-index mb-30">
                    <div className="tp-project-img">
                      <img
                        src={`assets/img/project/project-tab-5.jpg`}
                        className="img-fluid"
                        alt="img not found"
                      />
                    </div>
                    <div className="tp-project-text">
                      <div className="tp-project-text-content">
                        <span className="tp-project-subtitle">
                          Painting & cleaning service
                        </span>
                        <h4 className="tp-project-title">
                          <a href="tel:9500466996">
                            <i className="mr-10">
                              <FiPhoneCall />
                            </i>
                            95004 66996
                          </a>
                        </h4>
                      </div>
                      <div className="tp-project-text-icon">
                        <a href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks.">
                          <i className="text-white">
                            <ImWhatsapp />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-lg-12 col-md-12">
                  <div className="tp-project z-index mb-30">
                    <div className="tp-project-img">
                      <img
                        src={`assets/img/project/project-tab-6.jpg`}
                        className="img-fluid"
                        alt="img not found"
                      />
                    </div>
                    <div className="tp-project-text">
                      <div className="tp-project-text-content">
                        <span className="tp-project-subtitle">
                          Painting & cleaning service
                        </span>
                        <h4 className="tp-project-title">
                          <a href="tel:9500466996">
                            <i className="mr-10">
                              <FiPhoneCall />
                            </i>
                            95004 66996
                          </a>
                        </h4>
                      </div>
                      <div className="tp-project-text-icon">
                        <a href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks.">
                          <i className="text-white">
                            <ImWhatsapp />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
