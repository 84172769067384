import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { ImWhatsapp } from "react-icons/im";

const project_img = [
  { img: "pro1" },
  { img: "pro2" },
  { img: "pro3" },
  { img: "pro4" },
  { img: "pro5" },
  { img: "pro6" },
];
const ProjectsArea = () => {
  return (
    <>
      <div className="tp-prjects-area pt-120 pb-120">
        <div className="container">
          <div className="row pb-50">
            <div className="col-12">
              <div className="tp-prjects-tab-content">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="row">
                      {project_img &&
                        project_img.map((item, index) => (
                          <div className="col-lg-4 col-md-6" key={index}>
                            <div className="tp-project z-index mb-30">
                              <div className="tp-project-img">
                                <img
                                  src={`assets/img/project/project-tab-${
                                    index + 1
                                  }.jpg`}
                                  className="img-fluid"
                                  alt="img not found"
                                />
                              </div>
                              <div className="tp-project-text">
                                <div className="tp-project-text-content">
                                  <span className="tp-project-subtitle">
                                    Painting & cleaning service
                                  </span>
                                  <h4 className="tp-project-title">
                                    <a href="tel:9500466996">
                                      <i className="mr-10">
                                        <FiPhoneCall />
                                      </i>
                                      95004 66996
                                    </a>
                                  </h4>
                                </div>
                                <div className="tp-project-text-icon">
                                  <a href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks.">
                                    <i className="text-white">
                                      <ImWhatsapp />
                                    </i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsArea;
