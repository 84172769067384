import React from "react";
import { Link } from "react-router-dom";

const WorkingProcess = () => {
  return (
    <>
      <section className="tp-feature-area-three pt-120 pb-80 bg-gray-light">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="section-title-wrapper text-center mb-45 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                  Why Choose Fluoresen
                </h5>
                <h2 className="tp-section-title mb-25 heading-color-black">
                  Excellent Techniques For <br />
                  Effective Cleaning
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-sm-6">
              <div
                className="tp-feature-three text-center mb-30 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="tp-feature-three-icon mb-30">
                  <img
                    src="assets/img/icon/feature-bg.png"
                    className="img-fluid"
                    alt="img not found"
                  />
                  <i className="fa fa-id-card"></i>
                </div>
                <div className="tp-feature-three-text">
                  <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                    <Link to="/services">Background Checked</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="tp-feature-three text-center mb-30 wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div className="tp-feature-three-icon mb-30">
                  <img
                    src="assets/img/icon/feature-bg.png"
                    className="img-fluid"
                    alt="img not found"
                  />
                  <i className="flaticon-badge"></i>
                </div>
                <div className="tp-feature-three-text">
                  <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                    <Link to="/services">Trained professional</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="tp-feature-three text-center mb-30 wow fadeInUp"
                data-wow-delay="1s"
              >
                <div className="tp-feature-three-icon mb-30">
                  <img
                    src="assets/img/icon/feature-bg.png"
                    className="img-fluid"
                    alt="img not found"
                  />
                  <i className="flaticon-shuttle"></i>
                </div>
                <div className="tp-feature-three-text">
                  <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                    <Link to="/services">On Time Service</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="tp-feature-three text-center mb-30 wow fadeInUp"
                data-wow-delay="1.3s"
              >
                <div className="tp-feature-three-icon mb-30">
                  <img
                    src="assets/img/icon/feature-bg.png"
                    className="img-fluid"
                    alt="img not found"
                  />
                  <i className="fa fa-handshake"></i>
                </div>
                <div className="tp-feature-three-text">
                  <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                    <Link to="/services">Reliable & Transparent</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6"></div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="tp-feature-three text-center mb-30 wow fadeInUp"
                data-wow-delay="1s"
              >
                <div className="tp-feature-three-icon mb-30">
                  <img
                    src="assets/img/icon/feature-bg.png"
                    className="img-fluid"
                    alt="img not found"
                  />
                  <i className="flaticon-delivery-box"></i>
                </div>
                <div className="tp-feature-three-text">
                  <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                    <Link to="/services">Moneyback guarantee</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="tp-feature-three text-center mb-30 wow fadeInUp"
                data-wow-delay="1.3s"
              >
                <div className="tp-feature-three-icon mb-30">
                  <img
                    src="assets/img/icon/feature-bg.png"
                    className="img-fluid"
                    alt="img not found"
                  />
                  <i className="fa fa-check-square"></i>
                </div>
                <div className="tp-feature-three-text">
                  <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                    <Link to="/services">Standardised pricing</Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkingProcess;
