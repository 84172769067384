import React, { useState } from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { FaPlay } from "react-icons/fa";
import ServicesSide from "./ServicesSide";
import HomeTwoTestimonials from "../../HomeTwo/HomeTwo/HomeTwoTestimonials/HomeTwoTestimonials";
import { Helmet } from "react-helmet";

const KitchenCleaning = () => {
  const [isOpen, setOpen] = useState(false);
  const kitchenarticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Kitchen Cleaning Service in coimbatore",
    image: "https://fluoresen.com/assets/img/logo/logo-blue.png",
    telephone: "+91 9500466996",
    email: "",
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    url: "https://fluoresen.com/kitchen-cleaning-services-coimbatore",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Kitchen Cleaning Service in coimbatore | Fluoresen | +919500466996
        </title>
        <meta
          name="description"
          content="Spruce up your kitchen with a Kitchen Cleaning Service in coimbatore – shelves, chimney, exhaust, walls, window and floor cleaning. Show your love!"
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.fluoresen.com/kitchen-cleaning-services-coimbatore"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Kitchen Cleaning Service in coimbatore"
        />
        <meta
          property="og:description"
          content="Spruce up your kitchen with a Kitchen Cleaning Service in coimbatore – shelves, chimney, exhaust, walls, window and floor cleaning. Show your love!"
        />
        <meta
          property="og:url"
          content="https://www.fluoresen.com/kitchen-cleaning-services-coimbatore"
        />
        <meta
          property="og:site_name"
          content="Kitchen Cleaning Service in coimbatore"
        />
        <script type="application/ld+json">
          {JSON.stringify(kitchenarticleStructuredData)}
        </script>
      </Helmet>
      <Navigation />
      <Breadcrumb
        title="Kitchen "
        subTitle="Cleaning"
        smText="Kitchen Cleaning"
      ></Breadcrumb>
      {/* service body */}
      <>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId=""
          onClose={() => setOpen(false)}
        />

        <section className="tp-service-details-area pt-120 pb-90">
          <div className="container">
            <div className="row">
              <ServicesSide />
              <div className="col-xl-9 col-lg-8">
                <div className="tp-service-details">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/kitchen-cleaning-1.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/kitchen-cleaning-2.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="tp-section-title heading-color-black pt-10 mb-20">
                    Kitchen Cleaning
                  </h2>

                  <p className="mb-10">
                    We start with removing the dishes or clutter from the
                    countertops and table. Then, wipe down the counters, table,
                    and other surfaces with a damp cloth or sponge. Next, we
                    will clean the sink, faucet, chimney, removing the oil
                    stains and other fixtures with an all-purpose cleaner. After
                    that, you can sweep or vacuum the floor, and then mop it
                    with a cleaning solution.
                  </p>

                  <div className="tp-service-details-img mb-30 position-relative">
                    <img
                      src="assets/img/service/kitchen-cleaning-3.jpg"
                      alt="img not found"
                    />
                    <div className="tp-service-details-img-overlay">
                      <div className="tp-service-details-img-overlay-icon">
                        <button
                          onClick={() => setOpen(true)}
                          className="venobox"
                          data-autoplay="true"
                          data-vbtype="video"
                          href="#"
                        >
                          <i>
                            {" "}
                            <FaPlay className="text-white" />{" "}
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomeTwoTestimonials />
        </section>
      </>

      <Footer />
    </>
  );
};

export default KitchenCleaning;
