import React, { useState } from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { FaPlay } from "react-icons/fa";
import ServicesSide from "./ServicesSide";
import HomeTwoTestimonials from "../../HomeTwo/HomeTwo/HomeTwoTestimonials/HomeTwoTestimonials";
import { Helmet } from "react-helmet";

const ATermiteService = () => {
  const [isOpen, setOpen] = useState(false);
  const termitearticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Best Termite Control in Coimbatore",
    image: "https://fluoresen.com/assets/img/logo/logo-blue.png",
    telephone: "+91 9500466996",
    email: "",
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    url: "https://www.fluoresen.com/termite-control-coimbatore",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best Termite Control in Coimbatore | Fluoresen | +919500466996
        </title>
        <meta
          name="description"
          content="Termite Control in Coimbatore. Fluoresen  provides the best termite control service in Coimbatore. We are the best and certified pest control services company in Coimbatore."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.fluoresen.com/termite-control-coimbatore"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Best Termite Control in Coimbatore"
        />
        <meta
          property="og:description"
          content="Termite Control in Coimbatore. Fluoresen  provides the best termite control service in Coimbatore. We are the best and certified pest control services company in Coimbatore."
        />
        <meta
          property="og:url"
          content="https://www.fluoresen.com/termite-control-coimbatore"
        />
        <meta
          property="og:site_name"
          content="Best Termite Control in Coimbatore"
        />
        <script type="application/ld+json">
          {JSON.stringify(termitearticleStructuredData)}
        </script>
      </Helmet>
      <Navigation />
      <Breadcrumb
        title="A Termite"
        subTitle="Service"
        smText="A Termite Service"
      ></Breadcrumb>
      {/* service body */}
      <>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId=""
          onClose={() => setOpen(false)}
        />

        <section className="tp-service-details-area pt-120 pb-90">
          <div className="container">
            <div className="row">
              <ServicesSide />
              <div className="col-xl-9 col-lg-8">
                <div className="tp-service-details">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/a-termite-service-1.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/a-termite-service-2.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="tp-section-title heading-color-black pt-10 mb-20">
                    A Termite Service
                  </h2>

                  <p className="mb-10">
                    A termite cleaning service is a service that helps to
                    eliminate termites from a home or other building. This can
                    involve a variety of techniques, such as fumigation, baits,
                    or liquid treatments. We can help to identify the extent of
                    the infestation and recommend the best course of action to
                    eliminate the termites and prevent further damage to your
                    home or building.
                  </p>
                  <p className="mb-10">
                    For new buildings we are providing with 5 stages of termite
                    treatment with 20 years warranty
                  </p>

                  <div className="tp-service-details-img mb-30 position-relative">
                    <img
                      src="assets/img/service/a-termite-service-3.jpg"
                      alt="img not found"
                    />
                    <div className="tp-service-details-img-overlay">
                      <div className="tp-service-details-img-overlay-icon">
                        <button
                          onClick={() => setOpen(true)}
                          className="venobox"
                          data-autoplay="true"
                          data-vbtype="video"
                          href="#"
                        >
                          <i>
                            {" "}
                            <FaPlay className="text-white" />{" "}
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomeTwoTestimonials />
        </section>
      </>

      <Footer />
    </>
  );
};

export default ATermiteService;
