import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";

const CtaArea = () => {
  const form = useRef();
  const [inputVal, setinputVal] = useState({});

  const OnChanges = (event) => {
    setinputVal({ ...inputVal, [event.target.name]: event.target.value });
  };

  const OnsubmitEnquiry = (e) => {
    e.preventDefault();

    var phoneNo = inputVal.phone;
    var phoneVal = /^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!phoneNo.match(phoneVal)) {
      alert("Please provide valid phone number");
    } else {
      emailjs
        .sendForm(
          "service_xxpg0za",
          "template_38jzi1d",
          form.current,
          "lcHqrLJd9MQ9iuuem"
        )
        .then(
          (result) => {
            console.log(result.text);
            swal({
              title: "Email Sent Successfully",
              text: "Thank you! We've received your message. Someone from our team will contact you soon.",
              icon: "success",
              button: "OK",
            });
          },
          (error) => {
            console.log(error.text);
            swal({
              title: "Something Error!",
              text: "Please try aftor sometime",
              icon: "error",
              button: "Ok",
            });
          }
        );
      e.target.reset();
    }
  };
  return (
    <>
      <section className="tp-appoint-cta-area yellow-dark-bg pt-80 pb-65">
        <div className="container">
          <form ref={form} method="post" onSubmit={OnsubmitEnquiry}>
            <div className="row align-items-center custom-mar-20">
              <div className="col-xl-2 col-lg-12 custom-pad-20">
                <div className="tp-appoint wow fadeInUp" data-wow-delay=".1s">
                  <h4 className="tp-appoint-title">
                    Online <br /> Appointment{" "}
                  </h4>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 custom-pad-20">
                <div className="row align-items-center custom-mar-20">
                  <div className="col-lg-4 custom-pad-20">
                    <div
                      className="tp-appoint wow fadeInUp"
                      data-wow-delay=".3s"
                    >
                      <input
                        type="text"
                        name="fname"
                        required
                        placeholder="Full Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 custom-pad-20">
                    <div
                      className="tp-appoint wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <input
                        type="number"
                        name="phone"
                        placeholder="Phone Number"
                        onChange={OnChanges}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 custom-pad-20">
                    <div
                      className="tp-appoint select-field-arrow wow fadeInUp"
                      data-wow-delay=".7s"
                    >
                      <select name="service" required>
                        <option value="">Choose Subject</option>
                        <option value="House Cleaning">House Cleaning</option>
                        <option value="Office Cleaning">Office Cleaning</option>
                        <option value="Kitchen Cleaning">
                          Kitchen Cleaning
                        </option>
                        <option value="Club Cleaning">Club Cleaning</option>
                        <option value="Airless Spray Painting">
                          Airless Spray Painting
                        </option>
                        <option value="Water Tank Cleaning">
                          Water Tank Cleaning{" "}
                        </option>
                        <option value="Deep Toilet Cleaning">
                          Deep Toilet Cleaning
                        </option>
                        <option value="A Termite Service">
                          {" "}
                          A Termite Service
                        </option>
                        <option value="Carpet Cleaning">
                          {" "}
                          Carpet Cleaning
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 custom-pad-20">
                <div
                  className="tp-appoint text-end wow fadeInUp"
                  data-wow-delay=".9s"
                >
                  <button type="submit" className="theme-btn text-white">
                    <i className="flaticon-enter"></i> Submit Now
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default CtaArea;
