import React from "react";
import { Link } from "react-router-dom";

const ChoseArea = () => {
  return (
    <>
      <section className="tp-choose-area position-relative pt-120 pb-90">
        <div className="tp-choose-yellow-bg"></div>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-6 col-lg-8">
              <div className="tp-choose z-index">
                <div
                  className="tp-section-title-wrapper mb-105 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <h5 className="tp-section-subtitle common-yellow-shape common-white-shape section__sm__title">
                    Why Choose Fluoresen
                  </h5>
                  <h2 className="tp-section-title">
                    Expert House Cleaning Service Can Trust
                  </h2>
                </div>
                <div className="tp-choose-img mb-40 z-index d-block d-xl-none">
                  <img
                    src="assets/img/about/choose-img-1.jpg"
                    alt="img not found"
                  />
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="tp-choose-timeline ml-15 mb-30">
                      <div
                        className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp"
                        data-wow-delay=".6s"
                      >
                        <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                          <Link to="/services">Secure Services</Link>
                        </h4>
                        <p>
                          Our services are reliable and safe in terms of
                          products we use.
                        </p>
                      </div>
                      <div
                        className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp"
                        data-wow-delay=".4s"
                      >
                        <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                          <Link to="/services">Expert</Link>
                        </h4>
                        <p>
                          Serving this industry for 6+ years with expertise in
                          all niches of this industry.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-choose-timeline ml-15 mb-30">
                      <div
                        className="tp-choose-timeline-single pl-35 mb-55 wow fadeInUp"
                        data-wow-delay=".8s"
                      >
                        <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                          <Link to="/services">
                            Low Costing <br />
                          </Link>
                        </h4>
                        <p>We believe in providing cost-effectice services.</p>
                      </div>
                      <div
                        className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp"
                        data-wow-delay="1.2s"
                      >
                        <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                          <Link to="/services">On Time</Link>
                        </h4>
                        <p>We give On-Demand and On -Time service.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-12">
              <div
                className="tp-choose-img mb-30 z-index d-none d-xl-block wow fadeInUp"
                data-wow-delay=".6s"
              >
                <img
                  src="assets/img/about/why_choose.jpg"
                  alt="img not found"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChoseArea;
