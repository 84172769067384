import React from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
// import AboutArea from '../../Home/Home/AboutArea/AboutArea';
// import AboutBanner from "./AboutBanner/AboutBanner";
import AboutFaq from "./AboutFaq/AboutFaq";
// import AboutServices from "./AboutServices/AboutServices";
// import AboutTestimonial from "./AboutTestimonial/AboutTestimonial";
import AboutBreadcrumb from "./Breadcrumb/AboutBreadcrumb";
// import Breadcrumb from './Breadcrumb/AboutBreadcrumb';
import WorkingProcess from "./WorkingProcess/WorkingProcess";
import AboutAreaAbout from "../../Home/Home/AboutArea/AboutAreaAbout";
import { Helmet } from "react-helmet";

const About = () => {
  const aboutarticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "About fluoresen",
    image: "https://fluoresen.com/assets/img/logo/logo-blue.png",
    telephone: "+91 9500466996",
    email: "",
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    url: "https://fluoresen.com/about",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Who we are &amp; What Drives Us | About fluoresen</title>
        <meta
          name="description"
          content="Fluoresen is on a mission to provide clean, dust and allergen free homes to people in Bangalore with complete home cleaning services."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.fluoresen.com/about" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Who we are What Drives Us | About fluoresen"
        />
        <meta
          property="og:description"
          content="Fluoresen is on a mission to provide clean, dust and allergen free homes to people in Bangalore with complete home cleaning services."
        />
        <meta property="og:url" content="https://www.fluoresen.com/about" />
        <meta property="og:site_name" content="Who we are What Drives Us" />
        <script type="application/ld+json">
          {JSON.stringify(aboutarticleStructuredData)}
        </script>
      </Helmet>
      <Navigation />
      <AboutBreadcrumb />
      <AboutAreaAbout />
      <WorkingProcess />
      <AboutFaq />
      {/* <AboutServices />
      <AboutTestimonial />
      <AboutBanner /> */}
      <Footer />
    </>
  );
};

export default About;
