import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";

// import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen } from "react-icons/fa";

const ContactForm = () => {
  const form = useRef();
  const [inputVal, setinputVal] = useState({});

  const OnChanges = (event) => {
    setinputVal({ ...inputVal, [event.target.name]: event.target.value });
  };

  const OnsubmitEnquiry = (e) => {
    e.preventDefault();

    var phoneNo = inputVal.phone;
    var phoneVal = /^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!phoneNo.match(phoneVal)) {
      alert("Please provide valid phone number");
    } else {
      emailjs
        .sendForm(
          "service_xxpg0za",
          "template_38jzi1d",
          form.current,
          "lcHqrLJd9MQ9iuuem"
        )
        .then(
          (result) => {
            console.log(result.text);
            swal({
              title: "Email Sent Successfully",
              text: "Thank you! We've received your message. Someone from our team will contact you soon.",
              icon: "success",
              button: "OK",
            });
          },
          (error) => {
            console.log(error.text);
            swal({
              title: "Something Error!",
              text: "Please try aftor sometime",
              icon: "error",
              button: "Ok",
            });
          }
        );
      e.target.reset();
    }
  };
  return (
    <>
      <section className="tp-contact-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <div
                className="section-title-wrapper-two mb-50 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                  Get Free Estimate
                </h5>
                <h2 className="tp-section-title heading-color-black">
                  If you Have Any Query, Don’t Hesitate <br />
                  Contact with us{" "}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="row custom-mar-20">
                <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                  <div
                    className="tp-contact-info mb-40 wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <div className="tp-contact-info-icon">
                      <i>
                        {" "}
                        <FaMapMarkerAlt className="contact_icon" />{" "}
                      </i>
                    </div>
                    <div className="tp-contact-info-text">
                      <h4 className="tp-contact-info-title mb-15">Address</h4>
                      <p>
                        23, K. K. Pudhur Road Velandipalayam post Coimbatore
                        641025.
                      </p>
                      <br />
                      <p>
                        24, Madalayam Street, Janatha Nagar, Saravanampatty
                        Post, Coimbatore 641035.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                  <div
                    className="tp-contact-info mb-40 wow fadeInUp"
                    data-wow-delay=".6s"
                  >
                    <div className="tp-contact-info-icon">
                      <i>
                        {" "}
                        <FaPhoneAlt className="contact_icon" />{" "}
                      </i>
                    </div>
                    <div className="tp-contact-info-text">
                      <h4 className="tp-contact-info-title mb-15">Phone</h4>
                      <a href="tel:919500466996">+91 95004 66996</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                  <div
                    className="tp-contact-info mb-40 wow fadeInUp"
                    data-wow-delay=".8s"
                  >
                    <div className="tp-contact-info-icon">
                      <i>
                        {" "}
                        <FaEnvelopeOpen className="contact_icon" />{" "}
                      </i>
                    </div>
                    <div className="tp-contact-info-text">
                      <h4 className="tp-contact-info-title mb-15">Email</h4>
                      <a href="mailto:fluoresendil@gmail.com">
                        fluoresendil@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <form ref={form} method="post" onSubmit={OnsubmitEnquiry}>
                <div className="tp-contact-form">
                  <div className="row custom-mar-20">
                    <div className="col-md-6 custom-pad-20">
                      <div className="tp-contact-form-field mb-20">
                        <input
                          type="text"
                          required
                          name="fname"
                          placeholder="Full name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 custom-pad-20">
                      <div className="tp-contact-form-field mb-20">
                        <input
                          type="email"
                          required
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 custom-pad-20">
                      <div className="tp-contact-form-field mb-20">
                        <input
                          type="number"
                          required
                          name="phone"
                          onChange={OnChanges}
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 custom-pad-20">
                      <div className="tp-contact-form-field select-field-arrow mb-20">
                        <select name="service" required>
                          <option value="">Choose Subject</option>
                          <option value="House Cleaning">House Cleaning</option>
                          <option value="Office Cleaning">
                            Office Cleaning
                          </option>
                          <option value="Kitchen Cleaning">
                            Kitchen Cleaning
                          </option>
                          <option value="Club Cleaning">Club Cleaning</option>
                          <option value="Airless Spray Painting">
                            Airless Spray Painting
                          </option>
                          <option value="Water Tank Cleaning">
                            Water Tank Cleaning{" "}
                          </option>
                          <option value="Deep Toilet Cleaning">
                            Deep Toilet Cleaning
                          </option>
                          <option value="A Termite Service">
                            {" "}
                            A Termite Service
                          </option>
                          <option value="Carpet Cleaning">
                            {" "}
                            Carpet Cleaning
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 custom-pad-20">
                      <div className="tp-contact-form-field mb-20">
                        <textarea
                          name="message"
                          required
                          placeholder="Your Message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12 custom-pad-20">
                      <div className="tp-contact-form-field">
                        <button type="submit" className="theme-btn text-white">
                          <i className="flaticon-enter"></i> Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
