import React from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import ContactBreadcrumb from "./ContactBreadcrumb/ContactBreadcrumb";
import ContactForm from "./ContactForm/ContactForm";
import ContactMap from "./ContactMap/ContactMap";
import { Helmet } from "react-helmet";

const Contact = () => {
  const contactarticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Contact fluoresen",
    image: "https://fluoresen.com/assets/img/logo/logo-blue.png",
    telephone: "+91 9500466996",
    email: "",
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    url: "https://fluoresen.com/contact",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Book a service with Fluoresen from anywhere in Coimbatore | call +91
          95004 66996
        </title>
        <meta
          name="description"
          content="Book your house cleaning service or by filling a form on the website, emailing to fluoresendil@gmail.com or calling +91 95004 66996"
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.fluoresen.com/contact" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Book a service with Fluoresen from anywhere in Coimbatore | call +91 95004 66996"
        />
        <meta
          property="og:description"
          content="Book your house cleaning service or by filling a form on the website, emailing to fluoresendil@gmail.com or calling +91 95004 66996"
        />
        <meta property="og:url" content="https://www.fluoresen.com/contact" />
        <meta
          property="og:site_name"
          content="Cleaning services in coimbatore"
        />

        <script type="application/ld+json">
          {JSON.stringify(contactarticleStructuredData)}
        </script>
      </Helmet>
      <Navigation />
      <ContactBreadcrumb />
      <ContactMap />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Contact;
