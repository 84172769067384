import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import Collapsible from "react-collapsible";

const Sidebar = ({ show, handleClose }) => {
  const Services = <NavLink to="/services">Services </NavLink>;
  return (
    <>
      <div className="side__bar">
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="contact_nav text-white font-bold border-nav">
              <NavLink className="font-bold" to="/">
                Home
              </NavLink>
            </div>
            <div className="contact_nav text-white font-bold border-nav">
              <NavLink className="font-bold" to="/about">
                About
              </NavLink>
            </div>

            <Collapsible
              trigger={Services}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="/house-cleaning-service-coimbatore">
                    Cleaning an Entire House
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/bathroom-cleaning-services-coimbatore">
                    Deep Toilet Cleaning
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/house-painting-services-coimbatore">
                    Airless Spray Painting
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/water-tank-cleaning-services-coimbatore">
                    Water Tank Cleaning
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/kitchen-cleaning-services-coimbatore">
                    Kitchen Cleaning
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/termite-control-coimbatore">
                    A Termite Service
                  </NavLink>
                </li>
              </ul>
            </Collapsible>

            <div className="contact_nav text-white font-bold border-nav">
              <NavLink className="font-bold" to="/projects">
                Projects
              </NavLink>
            </div>
            <div className="contact_nav text-white font-bold border-nav">
              <NavLink className="font-bold" to="/contact">
                Contact
              </NavLink>
            </div>

            <div className="fix">
              <div className="side-info">
                <div className="side-info-content">
                  <div className="tp-mobile-menu"></div>
                  <div className="contact-infos mb-30">
                    <div className="contact-list mb-30">
                      <h4>Contact Info</h4>
                      <ul>
                        <li>
                          <i className="flaticon-pin"></i>23, K. K. Pudhur Road
                          Velandipalayam post Coimbatore 641025.
                        </li>
                        <li>
                          <i className="flaticon-pin"></i>24, Madalayam Street,
                          Janatha Nagar, Saravanampatty Post, Coimbatore 641035.
                        </li>
                        <li>
                          <i className="flaticon-email"></i>
                          <a href="mailto:fluoresendil@gmail.com">
                            fluoresendil@gmail.com
                          </a>
                        </li>
                        <li>
                          <i className="flaticon-phone-call"></i>
                          <a href="tel:+919500466996">+91 95004 66996</a>
                        </li>
                      </ul>
                      <div className="sidebar__menu--social">
                        <a className="text-white" href="/">
                          <i>
                            <FaFacebookF className="icon" />{" "}
                          </i>
                        </a>
                        <a className="text-white" href="/">
                          <i>
                            <BsTwitter className="icon" />{" "}
                          </i>
                        </a>
                        <a className="text-white" href="/">
                          <i>
                            <BsInstagram className="icon" />{" "}
                          </i>
                        </a>
                        <a className="text-white" href="/">
                          <i>
                            <AiOutlineGoogle className="icon" />{" "}
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
