import React from "react";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";

const AboutBreadcrumb = () => {
  return (
    <>
      <Breadcrumb
        title="About"
        subTitle="Fluoresen"
        smText="About"
      ></Breadcrumb>
    </>
  );
};

export default AboutBreadcrumb;
