import React from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import AboutArea from "./AboutArea/AboutArea";
// import BannerArea from "./BannerArea/BannerArea";
// import Blogs from "./Blogs/Blogs";
import ChoseArea from "./ChoseArea/ChoseArea";
import CtaArea from "./CtaArea/CtaArea";
import FactArea from "./FactArea/FactArea";
import HeroSection from "./HeroSection/HeroSection";
// import PricingArea from "./PricingArea/PricingArea";
// import ProgressArea from "./ProgressArea/ProgressArea";
import Projects from "./Projects/Projects";
import ServiceArea from "./ServiceArea/ServiceArea";
// import Testimonial from "./Testimonial/Testimonial";
import HomeTwoTestimonials from "../../HomeTwo/HomeTwo/HomeTwoTestimonials/HomeTwoTestimonials";
import { Helmet } from "react-helmet";

const Home = () => {
  const homearticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "house cleaning services coimbatore",
    image: "https://fluoresen.com/assets/img/logo/logo-blue.png",
    telephone: "+91 9500466996",
    email: "",
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    url: "https://fluoresen.com",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          house cleaning services coimbatore | Fluoresen | +919500466996{" "}
        </title>
        <meta
          name="description"
          content="House Cleaning Service in Coimbatore. We are providing house cleaning service in Coimbatore. Book now for house cleaning service in Coimbatore www.fluoresen.com"
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.fluoresen.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Deep House Cleaning Services in Coimbatore | Fluoresen"
        />
        <meta
          property="og:description"
          content="House Cleaning Service in Coimbatore. We are providing house cleaning service in Coimbatore. Book now for house cleaning service in Coimbatore www.fluoresen.com"
        />
        <meta property="og:url" content="https://www.fluoresen.com/" />
        <meta
          property="og:site_name"
          content="house cleaning services coimbatore"
        />
        <script type="application/ld+json">
          {JSON.stringify(homearticleStructuredData)}
        </script>
      </Helmet>
      <Navigation />
      <HeroSection />
      <CtaArea />
      <AboutArea />
      <FactArea />
      {/* <BannerArea /> */}
      <ServiceArea />
      <ChoseArea />
      {/* <PricingArea /> */}
      {/* <Testimonial /> */}
      <Projects />
      <HomeTwoTestimonials />
      {/* <ProgressArea />
      <Blogs /> */}
      <Footer />
    </>
  );
};

export default Home;
