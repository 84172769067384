import React from "react";
import HomeServiceItem from "../../../../components/HomeServiceItem/HomeServiceItem";

const ServiceArea = () => {
  return (
    <>
      <section className="tp-services-area theme-dark-bg">
        <div className="tp-custom-container">
          <div className="tp-services-bg grey-bg pt-120 pb-90 z-index">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div
                    className="tp-section-title-wrapper text-center mb-55 wow fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-25">
                      Our Popular Services
                    </h5>
                    <h2 className="tp-section-title">
                      Our Success Cleaning <br />
                      Up your Mess
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <HomeServiceItem
                  icon_name="flaticon-snowfall"
                  title="House Cleaning"
                  link="/house-cleaning-service-coimbatore"
                  des="Cleaning an entire house can be quite the task, but it's definitely manageable. Our team will start..."
                />

                <HomeServiceItem
                  icon_name="fas fa-toilet"
                  title="Toilet Cleaning"
                  link="/bathroom-cleaning-services-coimbatore"
                  des="Deep toilet cleaning is important to maintain good hygiene and prevent the buildup of bacteria..."
                />

                <HomeServiceItem
                  icon_name="flaticon-house-cleaning"
                  title="Spray Painting"
                  link="/house-painting-services-coimbatore"
                  des="Airless spray painting is a method of applying paint to a surface using a high-pressure spray gun..."
                />

                <HomeServiceItem
                  icon_name="flaticon-cleaning"
                  title="Watertank Cleaning"
                  link="/water-tank-cleaning-services-coimbatore"
                  des="Water tank cleaning with a high-pressure water jet and submersible pump is a method..."
                />

                <HomeServiceItem
                  icon_name="flaticon-pot"
                  title="Kitchen Cleaning"
                  link="/kitchen-cleaning-services-coimbatore"
                  des="We start with removing the  dishes or clutter from the countertops and table. Then, wipe down the..."
                />

                <HomeServiceItem
                  icon_name="fas fa-spider"
                  title="A Termite Service"
                  link="/termite-control-coimbatore"
                  des="A termite service is a service that helps to eliminate termites from a home or other building. This..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceArea;
