import React from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServicesSide = () => {
  return (
    <>
      <div className="col-xl-3 col-lg-4">
        <div className="tp-faqs-left">
          <div className="tp-faqs-left-sidebar tp-services-sidebar mb-30">
            <h4 className="tp-faqs-left-sidebar-title">Our Services</h4>
            <ul>
              <li>
                <Link to="/house-cleaning-service-coimbatore">
                  Cleaning an Entire House
                  <i>
                    {" "}
                    <FaArrowAltCircleRight />{" "}
                  </i>{" "}
                </Link>
              </li>
              <li>
                <Link to="/bathroom-cleaning-services-coimbatore">
                  Deep Toilet Cleaning{" "}
                  <i>
                    {" "}
                    <FaArrowAltCircleRight />{" "}
                  </i>{" "}
                </Link>
              </li>
              <li>
                <Link to="/house-painting-services-coimbatore">
                  Airless Spray Painting{" "}
                  <i>
                    {" "}
                    <FaArrowAltCircleRight />{" "}
                  </i>{" "}
                </Link>
              </li>
              <li>
                <Link to="/water-tank-cleaning-services-coimbatore">
                  Water Tank Cleaning{" "}
                  <i>
                    {" "}
                    <FaArrowAltCircleRight />{" "}
                  </i>{" "}
                </Link>
              </li>
              <li>
                <Link to="/kitchen-cleaning-services-coimbatore">
                  Kitchen Cleaning{" "}
                  <i>
                    {" "}
                    <FaArrowAltCircleRight />{" "}
                  </i>{" "}
                </Link>
              </li>
              <li>
                <Link to="/termite-control-coimbatore">
                  A Termite Service{" "}
                  <i>
                    {" "}
                    <FaArrowAltCircleRight />{" "}
                  </i>{" "}
                </Link>
              </li>
            </ul>
          </div>
          <div className="tp-faqs-left-img mb-30">
            <Link to="#">
              <img
                src="assets/img/service/fluoresen-cleaning-1.jpg"
                className="img"
                alt="img not found"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSide;
