import React from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import ServicesArea from "../../Home/Home/ServiceArea/ServiceArea";
import ServicesBreadcrumb from "./ServicesBreadcrumb/ServicesBreadcrumb";
import ServicesTestimonials from "./ServicesTestimonial/ServicesTestimonials";

const Services = () => {
  return (
    <>
      <Navigation />
      <ServicesBreadcrumb />
      <ServicesArea />
      <ServicesTestimonials />

      <Footer />
    </>
  );
};

export default Services;
