import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";
import { FaBars } from "react-icons/fa";
import { ImPhone, ImWhatsapp } from "react-icons/im";

const Navigation = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // sticky state
  const [sticky, setSticky] = useState(false);
  const stickyNavbar = () => {
    if (window.scrollY > 80) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  window.addEventListener("scroll", stickyNavbar);
  return (
    <>
      <header>
        <div className="tp-header-area">
          <div className="tp-header-top theme-dark-bg pt-10 pb-50 d-none d-xl-block">
            <div className="tp-custom-container">
              <div className="row align-items-center">
                <div className="col-xxl-4 col-xl-5">
                  <div className="tp-header-top-info">
                    <div className="tp-header-top-info-single pr-40 mr-40 border-right-1">
                      <div className="tp-header-top-info-single-icon mr-10">
                        <i className="flaticon-pin"></i>
                      </div>
                      <div className="tp-header-top-info-single-text">
                        <span className="tp-header-top-info-single-label">
                          Free Contact
                        </span>
                        <span className="tp-header-top-info-single-content font-medium">
                          COIMBATORE
                        </span>
                      </div>
                    </div>
                    <div className="tp-header-top-info-single">
                      <div className="tp-header-top-info-single-icon mr-15">
                        <i className="flaticon-email"></i>
                      </div>
                      <div className="tp-header-top-info-single-text">
                        <span className="tp-header-top-info-single-label">
                          Email us
                        </span>

                        <a
                          href="mailto:fluoresendil@gmail.com"
                          className="tp-header-top-info-single-content font-medium text-white"
                        >
                          fluoresendil@gmail.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-2">
                  <div className="header-logo text-center">
                    <Link to="/">
                      <img
                        src="assets/img/logo/logo-white.png"
                        className="img-fluid imgLogo"
                        alt="logo not found"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-5">
                  <div className="tp-header-top-info justify-content-end">
                    <div className="tp-header-top-info-single mr-85">
                      <div className="tp-header-top-info-single-icon tp-header-top-info-single-icon-call mr-10">
                        <i className="flaticon-phone-call"></i>
                      </div>
                      <div className="tp-header-top-info-single-text">
                        <span className="tp-header-top-info-single-label">
                          Free Call
                        </span>
                        <a
                          href="tel:9500466996"
                          className="tp-header-top-info-single-content font-medium text-white"
                        >
                          +91 95004 66996
                        </a>
                      </div>
                    </div>
                    <div className="tp-header-top-info-single">
                      <div className="tp-header-top-info-single-btn">
                        <a
                          href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks."
                          className="yellow-btn"
                        >
                          <i className="flaticon-enter"></i> Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              sticky
                ? "sticky-menu tp-header-menu-area tp-transparent-header-menu header-sticky"
                : "tp-header-menu-area tp-transparent-header-menu header-sticky"
            }
          >
            <div className="container">
              <div className="row justify-content-xl-center align-items-center">
                <div className="col-xl-2 col-8 tp-sticky-column">
                  <div className="tp-sticky-logo">
                    <Link to="/">
                      <img
                        src="assets/img/logo/logo-blue.png"
                        className="img-fluid imgLogo"
                        alt="logo not found"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-8 col-4">
                  <div className="tp-main-menu-bg">
                    <div className="tp-main-menu">
                      <nav id="tp-mobile-menu">
                        <ul className="text-center">
                          <li>
                            <NavLink to="/">Home</NavLink>
                            {/* <ul className="sub-menu">
                              <li>
                                <NavLink to="/">Home Style 1</NavLink>
                              </li>
                              <li>
                                <NavLink to="/homeTwo">Home Style 2</NavLink>
                              </li>
                              <li>
                                <NavLink to="/homeThree">Home Style 3</NavLink>
                              </li>
                            </ul> */}
                          </li>
                          <li>
                            <NavLink to="/about">About</NavLink>
                          </li>
                          {/* <li className="menu-item-has-children">
                            <NavLink to="/about">Pages </NavLink>
                            <ul className="sub-menu">
                              <li>
                                <NavLink to="/about">About</NavLink>
                              </li>
                              <li>
                                <NavLink to="/appointment">Appointment</NavLink>
                              </li>
                              <li>
                                <NavLink to="/pricing">Pricing</NavLink>
                              </li>
                              <li>
                                <NavLink to="/team">Team</NavLink>
                              </li>
                              <li>
                                <NavLink to="/teamDetails">
                                  Team Details
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/faq">Faq</NavLink>
                              </li>
                            </ul>
                          </li> */}
                          <li className="menu-item-has-children">
                            <NavLink to="/services">Services </NavLink>
                            <ul className="sub-menu">
                              <li>
                                <NavLink to="/house-cleaning-service-coimbatore">
                                  Cleaning an Entire House
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/bathroom-cleaning-services-coimbatore">
                                  Deep Toilet Cleaning
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/house-painting-services-coimbatore">
                                  Airless Spray Painting
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/water-tank-cleaning-services-coimbatore">
                                  Water Tank Cleaning
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/kitchen-cleaning-services-coimbatore">
                                  Kitchen Cleaning
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/termite-control-coimbatore">
                                  A Termite Service
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="">
                            <NavLink to="/projects">Projects</NavLink>
                          </li>

                          <li>
                            <NavLink to="/contact">Contact</NavLink>
                          </li>
                        </ul>
                      </nav>
                    </div>

                    <div className="side-menu-icon d-xl-none text-end">
                      <button
                        onClick={handleShow}
                        className="side-toggle border-0 bg-transparent ss"
                      >
                        <i>
                          {" "}
                          <FaBars className="bar_icon" />{" "}
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 tp-sticky-column-btn">
                  <div className="tp-sticky-btn text-end">
                    <a
                      href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks."
                      className="theme-btn justify-content-end text-white"
                    >
                      <i className="flaticon-enter"></i> Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="fixWhatsapp">
        <span className="whatsAppbtn">
          <a href="https://api.whatsapp.com/send?phone=919500466996&text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks.">
            <ImWhatsapp /> whatsApp
          </a>
        </span>
      </div>
      <div className="fixphone">
        <span className="Phonebtn">
          <a href="tel:9500466996">
            <ImPhone /> Call Now
          </a>
        </span>
      </div>

      <Sidebar show={show} handleClose={handleClose}></Sidebar>
    </>
  );
};

export default Navigation;
