import React, { useState } from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { FaPlay } from "react-icons/fa";
import ServicesSide from "./ServicesSide";
import HomeTwoTestimonials from "../../HomeTwo/HomeTwo/HomeTwoTestimonials/HomeTwoTestimonials";
import { Helmet } from "react-helmet";

const CleaningAnEntireHouse = () => {
  const [isOpen, setOpen] = useState(false);
  const housearticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "house cleaning service coimbatore",
    image: "https://fluoresen.com/assets/img/logo/logo-blue.png",
    telephone: "+91 9500466996",
    email: "",
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    url: "https://fluoresen.com/house-cleaning-service-coimbatore",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          House Cleaning Service in Coimbatore | Home Deep Cleaning Service
          Coimbatore
        </title>
        <meta
          name="description"
          content="Professional house cleaning service coimbatore - kitchen, bathrooms, home cleanup for an allergy-free home. call: +91 95004 66996"
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.fluoresen.com/house-cleaning-service-coimbatore"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="house cleaning service coimbatore" />
        <meta
          property="og:description"
          content="Professional house cleaning service coimbatore - kitchen, bathrooms, home cleanup for an allergy-free home. call: +91 95004 66996"
        />
        <meta
          property="og:url"
          content="https://www.fluoresen.com/house-cleaning-service-coimbatore"
        />
        <meta
          property="og:site_name"
          content="house cleaning service coimbatore"
        />

        <script type="application/ld+json">
          {JSON.stringify(housearticleStructuredData)}
        </script>
      </Helmet>
      <Navigation />
      <Breadcrumb
        title="House"
        subTitle="Cleaning"
        smText="House Cleaning"
      ></Breadcrumb>
      {/* service body */}
      <>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId=""
          onClose={() => setOpen(false)}
        />

        <section className="tp-service-details-area pt-120 pb-90">
          <div className="container">
            <div className="row">
              <ServicesSide />
              <div className="col-xl-9 col-lg-8">
                <div className="tp-service-details">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/house-cleaning-1.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/house-cleaning-2.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="tp-section-title heading-color-black pt-10 mb-20">
                    Cleaning an Entire House
                  </h2>
                  <p className="mb-20">
                    Cleaning an entire house can be quite the task, but it's
                    definitely manageable. Our team will start with one room at
                    a time and breaking down the cleaning process into smaller
                    tasks. For example, we start with dusting all the surfaces
                    in one room, then move onto vacuuming the floors, cleaning
                    the windows and finish up by wiping down any hard surfaces.
                    This way, our team can focus on one task at a time and feel
                    a sense of accomplishment as we complete each one.
                  </p>

                  <div className="tp-service-details-img mb-30 position-relative">
                    <img
                      src="assets/img/service/house-cleaning-3.jpg"
                      alt="img not found"
                    />
                    <div className="tp-service-details-img-overlay">
                      <div className="tp-service-details-img-overlay-icon">
                        <button
                          onClick={() => setOpen(true)}
                          className="venobox"
                          data-autoplay="true"
                          data-vbtype="video"
                          href="#"
                        >
                          <i>
                            {" "}
                            <FaPlay className="text-white" />{" "}
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomeTwoTestimonials />
        </section>
      </>

      <Footer />
    </>
  );
};

export default CleaningAnEntireHouse;
