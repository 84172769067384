import React from "react";
import { Link } from "react-router-dom";

const AboutAreaAbout = () => {
  return (
    <>
      <section className="tp-about-area position-relative pt-120 pb-15 fix">
        <div className="tp-about-shape">
          <img
            src="assets/img/about/about-shape-1.jpg"
            className="img-fluid"
            alt="img not found"
          />
        </div>
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-5 col-12">
              <div
                className="tp-about-img z-index wow fadeInUp"
                data-wow-delay=".3s"
              >
                <img
                  src="assets/img/about/about-img-1.jpg"
                  alt="img not found"
                />
              </div>
            </div>
            <div className="col-xl-6 col-md-10">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30">
                  <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    About our Company
                  </h5>
                  <h2 className="about-h2">
                    Work hard in silence, <br />
                    let your success be your noise
                  </h2>
                </div>
                <p className="mb-10">
                  Flurosence cleaning services is a company with 6 years of
                  experience.We started off with cleaning services in a small
                  residential area and some commercial company and now we are
                  expanding our services into high rise Colleges, Schools,
                  Residential and commercial area with hygiene service and
                  maintenance.{" "}
                </p>
                <p className="mb-10">
                  We are passionate about providing a flexible and customize
                  service where every customer is treated equally no matter how
                  large and small the area is. We will ensure that every
                  property is maintained at the consistent level of quality by
                  doing one-off spot checks and scheduled visits, by our skilled
                  employees.
                </p>
                <p className="mb-30">
                  Our areas of expertise include Industrial, Commercial,
                  Corporate Office, Hospital and Residential Cleaning. We aim to
                  provide quality service to our customer as we believe that
                  clean and healthy environment is beneficial to one’s physical
                  and mental health.
                </p>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="tp-about-author mb-30">
                      <div className="tp-about-author-img">
                        <img
                          src="assets/img/about/sendil-fluoresen.png"
                          className="img-fluid"
                          alt="img not found"
                        />
                      </div>
                      <div className="tp-about-author-text">
                        <h4 className="tp-about-author-text-title">
                          C C Sendil Kumar
                        </h4>
                        <span>CEO - Fluoresen</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-about-number mb-30">
                      <div className="tp-about-number-icon">
                        <i className="flaticon-phone-call-1"></i>
                      </div>
                      <div className="tp-about-number-text">
                        <span>Free Consutacy</span>
                        <Link
                          to={{ pathname: "tel:+919500466996" }}
                          target="_blank"
                        >
                          +91 95004 66996
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutAreaAbout;
