import React, { useState } from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { FaPlay } from "react-icons/fa";
import ServicesSide from "./ServicesSide";
import HomeTwoTestimonials from "../../HomeTwo/HomeTwo/HomeTwoTestimonials/HomeTwoTestimonials";
import { Helmet } from "react-helmet";

const AirlessSprayPainting = () => {
  const [isOpen, setOpen] = useState(false);
  const paintarticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "House painting services in coimbatore",
    image: "https://fluoresen.com/assets/img/logo/logo-blue.png",
    telephone: "+91 9500466996",
    email: "",
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    url: "https://fluoresen.com/house-painting-services-coimbatore",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>House painting services in coimbatore</title>
        <meta
          name="description"
          content="Set up your office or residential space in style with professional painters in coimbatore at Fluoresen! Book now House painting services in coimbatore"
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.fluoresen.com/house-painting-services-coimbatore"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="House painting services in coimbatore"
        />
        <meta
          property="og:description"
          content="Set up your office or residential space in style with professional painters in coimbatore at Fluoresen! Book now House painting services in coimbatore"
        />
        <meta
          property="og:url"
          content="https://www.fluoresen.com/house-painting-services-coimbatore"
        />
        <meta
          property="og:site_name"
          content="House painting services in coimbatore"
        />

        <script type="application/ld+json">
          {JSON.stringify(paintarticleStructuredData)}
        </script>
      </Helmet>
      <Navigation />
      <Breadcrumb
        title="Airless Spray"
        subTitle="Painting"
        smText="Airless Spray Painting"
      ></Breadcrumb>
      {/* service body */}
      <>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="O8q5Y5fkUg4"
          onClose={() => setOpen(false)}
        />

        <section className="tp-service-details-area pt-120 pb-90">
          <div className="container">
            <div className="row">
              <ServicesSide />
              <div className="col-xl-9 col-lg-8">
                <div className="tp-service-details">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/airless-spray-painting-1.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/airless-spray-painting-2.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="tp-section-title heading-color-black pt-10 mb-20">
                    Airless Spray Painting
                  </h2>

                  <p className="mb-10">
                    Airless spray painting is a method of applying paint to a
                    surface using a high-pressure spray gun. Unlike traditional
                    spray guns, which use compressed air to atomize the paint,
                    airless spray guns use a pump to force the paint through a
                    small nozzle at high pressure. This creates a fine mist of
                    paint that can be applied quickly and evenly to large
                    surfaces, such as walls or ceilings.
                  </p>

                  <div className="tp-service-details-img mb-30 position-relative">
                    <img
                      src="assets/img/service/airless-spray-painting-3.jpg"
                      alt="img not found"
                    />
                    <div className="tp-service-details-img-overlay">
                      <div className="tp-service-details-img-overlay-icon">
                        <button
                          onClick={() => setOpen(true)}
                          className="venobox"
                          data-autoplay="true"
                          data-vbtype="video"
                          href="#"
                        >
                          <i>
                            {" "}
                            <FaPlay className="text-white" />{" "}
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomeTwoTestimonials />
        </section>
      </>

      <Footer />
    </>
  );
};

export default AirlessSprayPainting;
