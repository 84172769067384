import React, { useState } from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { FaPlay } from "react-icons/fa";
import ServicesSide from "./ServicesSide";
import HomeTwoTestimonials from "../../HomeTwo/HomeTwo/HomeTwoTestimonials/HomeTwoTestimonials";
import { Helmet } from "react-helmet";

const Deeptoiletcleaning = () => {
  const [isOpen, setOpen] = useState(false);
  const toiletarticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Bathroom Cleaning Services in Coimbatore",
    image: "https://fluoresen.com/assets/img/logo/logo-blue.png",
    telephone: "+91 9500466996",
    email: "",
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    url: "https://fluoresen.com/bathroom-cleaning-services-coimbatore",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Deep Bathroom Cleaning Services in Coimbatore | Toilet Cleaning
          Services in Coimbatore
        </title>
        <meta
          name="description"
          content="Fluoresen is providing deep bathroom cleaning services in Coimbatore at very affordable price. For our Toilet Cleaning Services in Coimbatore call us today!"
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.fluoresen.com/bathroom-cleaning-services-coimbatore"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Deep Bathroom Cleaning Services in Coimbatore  | Toilet Cleaning Services in Coimbatore"
        />
        <meta
          property="og:description"
          content="Fluoresen is providing deep bathroom cleaning services in Coimbatore at very affordable price. For our Toilet Cleaning Services in Coimbatore call us today!"
        />
        <meta
          property="og:url"
          content="https://www.fluoresen.com/bathroom-cleaning-services-coimbatore"
        />
        <meta
          property="og:site_name"
          content="Deep Bathroom Cleaning Services in Coimbatore"
        />

        <script type="application/ld+json">
          {JSON.stringify(toiletarticleStructuredData)}
        </script>
      </Helmet>
      <Navigation />
      <Breadcrumb
        title="Toilet"
        subTitle="Cleaning"
        smText="Toilet Cleaning"
      ></Breadcrumb>
      {/* service body */}
      <>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId=""
          onClose={() => setOpen(false)}
        />

        <section className="tp-service-details-area pt-120 pb-90">
          <div className="container">
            <div className="row">
              <ServicesSide />
              <div className="col-xl-9 col-lg-8">
                <div className="tp-service-details">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/toilet-cleaning-1.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/toilet-cleaning-2.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="tp-section-title heading-color-black pt-10 mb-20">
                    Deep Toilet Cleaning
                  </h2>

                  <p className="mb-10">
                    Deep toilet cleaning is important to maintain good hygiene
                    and prevent the buildup of bacteria and other harmful
                    microorganisms. We start by removing any excess water from
                    the bowl and applying a cleaning solution, it 80%chemical
                    and 20% acid as one product which is manufactured by our
                    company and it is applied to the inside of the bowl to and
                    the rim to remove the salt strains. Then, we use a toilet
                    brush to scrub inside of the bowl and the rim, we make sure
                    to get into all the nooks ,pipe fittings and crannies. We
                    use a scrub brush to clean the outside of the toilet,
                    including the base and any hard-to-reach areas.
                  </p>
                  <p className="mb-20">
                    Finally, we rinse the toilet thoroughly with water and dry
                    it . It's important to deep clean your toilet regularly to
                    keep it looking and smelling fresh, and to prevent the
                    buildup of harmful bacteria.
                  </p>

                  <div className="tp-service-details-img mb-30 position-relative">
                    <img
                      src="assets/img/service/toilet-cleaning-3.jpg"
                      alt="img not found"
                    />
                    <div className="tp-service-details-img-overlay">
                      <div className="tp-service-details-img-overlay-icon">
                        <button
                          onClick={() => setOpen(true)}
                          className="venobox"
                          data-autoplay="true"
                          data-vbtype="video"
                          href="#"
                        >
                          <i>
                            {" "}
                            <FaPlay className="text-white" />{" "}
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomeTwoTestimonials />
        </section>
      </>

      <Footer />
    </>
  );
};

export default Deeptoiletcleaning;
