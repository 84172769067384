import React, { useState } from "react";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { FaPlay } from "react-icons/fa";
import ServicesSide from "./ServicesSide";
import HomeTwoTestimonials from "../../HomeTwo/HomeTwo/HomeTwoTestimonials/HomeTwoTestimonials";
import { Helmet } from "react-helmet";

const WaterTankCleaning = () => {
  const [isOpen, setOpen] = useState(false);
  const tankarticleStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Water Tank Cleaning services in coimbatore",
    image: "https://fluoresen.com/assets/img/logo/logo-blue.png",
    telephone: "+91 9500466996",
    email: "",
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    url: "https://fluoresen.com/water-tank-cleaning-services-coimbatore",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Water Tank Cleaning services in coimbatore | call: +91 95004 66996
        </title>
        <meta
          name="description"
          content="We are an expert and experience Water Tank Cleaning Service in coimbatore. Water Tank Cleaning in coimbatore, Free quotation and Quality Service Assured."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.fluoresen.com/water-tank-cleaning-services-coimbatore"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Water Tank Cleaning services in coimbatore"
        />
        <meta
          property="og:description"
          content="We are an expert and experience Water Tank Cleaning Service in coimbatore. Water Tank Cleaning in coimbatore, Free quotation and Quality Service Assured."
        />
        <meta
          property="og:url"
          content="https://www.fluoresen.com/water-tank-cleaning-services-coimbatore"
        />
        <meta
          property="og:site_name"
          content="Water Tank Cleaning services in coimbatore"
        />
        <script type="application/ld+json">
          {JSON.stringify(tankarticleStructuredData)}
        </script>
      </Helmet>
      <Navigation />
      <Breadcrumb
        title="Water Tank "
        subTitle="Cleaning"
        smText="Water Tank Cleaning"
      ></Breadcrumb>
      {/* service body */}
      <>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId=""
          onClose={() => setOpen(false)}
        />

        <section className="tp-service-details-area pt-120 pb-90">
          <div className="container">
            <div className="row">
              <ServicesSide />
              <div className="col-xl-9 col-lg-8">
                <div className="tp-service-details">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/tank-cleaning-1.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="tp-service-details-img mb-30">
                        <img
                          src="assets/img/service/tank-cleaning-2.jpg"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="tp-section-title heading-color-black pt-10 mb-20">
                    Water Tank Cleaning
                  </h2>

                  <p className="mb-10">
                    Water tank cleaning with a high-pressure water jet and
                    submersible pump is a method of removing dirt, debris, and
                    other contaminants from the inside of a water tank using a
                    powerful stream of water. This process involves using a
                    specialized machine that pumps water at high pressure
                    through a hose and nozzle, which is then directed at the
                    interior surfaces of the tank.
                  </p>
                  <p className="mb-10">
                    The force of the water stream is strong enough to dislodge
                    and remove any buildup or sediment that has accumulated on
                    the walls or floor of the tank. After the cleaning is
                    complete, the tank is drained and rinsed thoroughly to
                    remove any remaining debris or cleaning solution. Water tank
                    cleaning with a high-pressure water jet is an effective way
                    to ensure that your water supply remains clean and safe for
                    consumption.
                  </p>

                  <div className="tp-service-details-img mb-30 position-relative">
                    <img
                      src="assets/img/service/tank-cleaning-3.jpg"
                      alt="img not found"
                    />
                    <div className="tp-service-details-img-overlay">
                      <div className="tp-service-details-img-overlay-icon">
                        <button
                          onClick={() => setOpen(true)}
                          className="venobox"
                          data-autoplay="true"
                          data-vbtype="video"
                          href="#"
                        >
                          <i>
                            {" "}
                            <FaPlay className="text-white" />{" "}
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomeTwoTestimonials />
        </section>
      </>

      <Footer />
    </>
  );
};

export default WaterTankCleaning;
