import React from "react";

const ContactMap = () => {
  return (
    <>
      <div className="tp-map-area pt-120 pb-115">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="tp-contact-map">
                <iframe
                  title="contact map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d10304.767734550062!2d76.93411247140298!3d11.033355380987357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1687778914647!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>

            <div className="col-lg-6 col-sm-12">
              <div className="tp-contact-map">
                <iframe
                  title="contact map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3268.9894249015606!2d76.98975523051112!3d11.06290600168834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f71c6d60dff9%3A0x8f134aaed3c419f0!2sFluoresen%20painting%20%26%20Cleaning%20Services!5e0!3m2!1sen!2sin!4v1687957665429!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMap;
