import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, {
  Pagination,
  Autoplay,
  A11y,
  EffectFade,
  EffectCards,
} from "swiper";
import { Carousel } from "react-bootstrap";
import HomeHeroSingleSlide from "../../../../components/HomeHeroSingleSlide/HomeHeroSingleSlide";
import HomeHeroSingleSlide2 from "../../../../components/HomeHeroSingleSlide/HomeHeroSingleSlide2";
import HomeHeroSingleSlide3 from "../../../../components/HomeHeroSingleSlide/HomeHeroSingleSlide3";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, A11y, EffectFade, EffectCards]);

const HeroSection = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="O8q5Y5fkUg4"
        onClose={() => setOpen(false)}
      />

      <section className="tp-slider-area fix">
        <div className="tp-slider-active home_hero_slide swiper-container common-dots">
          <Carousel interval={3000} fade={true} indicators={true}>
            <Carousel.Item>
              <HomeHeroSingleSlide setOpen={setOpen} />
            </Carousel.Item>

            <Carousel.Item>
              <HomeHeroSingleSlide2 setOpen={setOpen} />
            </Carousel.Item>

            <Carousel.Item>
              <HomeHeroSingleSlide3 setOpen={setOpen} />
            </Carousel.Item>
          </Carousel>

          <div className="swiper-paginations slide-dots"></div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
