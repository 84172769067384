import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";

import ServiceTestimonial from "../../../../components/ServiceTestimonial/ServiceTestimonial";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css/pagination";

SwiperCore.use([Navigation, Pagination]);

const ServicesTestimonials = () => {
  const form = useRef();
  const [inputVal, setinputVal] = useState({});

  const OnChanges = (event) => {
    setinputVal({ ...inputVal, [event.target.name]: event.target.value });
  };

  const OnsubmitEnquiry = (e) => {
    e.preventDefault();

    var phoneNo = inputVal.phone;
    var phoneVal = /^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!phoneNo.match(phoneVal)) {
      alert("Please provide valid phone number");
    } else {
      emailjs
        .sendForm(
          "service_xxpg0za",
          "template_38jzi1d",
          form.current,
          "lcHqrLJd9MQ9iuuem"
        )
        .then(
          (result) => {
            console.log(result.text);
            swal({
              title: "Email Sent Successfully",
              text: "Thank you! We've received your message. Someone from our team will contact you soon.",
              icon: "success",
              button: "OK",
            });
          },
          (error) => {
            console.log(error.text);
            swal({
              title: "Something Error!",
              text: "Please try aftor sometime",
              icon: "error",
              button: "Ok",
            });
          }
        );
      e.target.reset();
    }
  };
  return (
    <>
      <section className="tp-testimonial-area-two tp-testimonial-area-four position-relative">
        <div className="tp-testimonial-area-two-shape"></div>
        <div className="container position-relative">
          <div className="tp-testimonial-title-wrapper pt-120">
            <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
              Our Popular Services
            </h5>
            <h2 className="tp-section-title heading-color-black fs-45">
              Customer Says About <br />
              Our Service
            </h2>
          </div>
        </div>
        <div className="tp-testimonial-divide">
          <div className="tp-testimonial-two-wrapper z-index mb-0">
            <div className="tp-testimonial-two-active swiper-container common-dots pb-40">
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                style={{ paddingBottom: "80px" }}
                className="swiper-wrapper"
                pagination={{ clickable: true }}
                autoplay={{ delay: 4000 }}
                breakpoints={{
                  550: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 2,
                  },
                }}
                navigation={{
                  nextEl: ".service-button-next",
                  prevEl: ".service-button-prev",
                }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <ServiceTestimonial
                    testi_img_num="female"
                    title="Customer"
                    name="Kalpana"
                    msg="Great service. I got my flat deep cleaned by Fluoresen. They did a wonderful job and have definitely exceed my expectations. The staff was very polite. They best thing i liked about them is that they made sure I am happy and satisfied with the work before leaving. I highly recommend fluoresen home cleaning..!"
                  ></ServiceTestimonial>
                </SwiperSlide>

                <SwiperSlide>
                  <ServiceTestimonial
                    testi_img_num="male"
                    title="Customer"
                    name="Sathish kumar"
                    msg="Loved their service!!! They provided us their deep cleaning service  and I was shocked by the results, they changed the complete look of my house and made it  sparkling cleaning and immaculate. Their workers were very professional and cooperative throughout. Saifished with their services, would highly recommend fluoresen services"
                  ></ServiceTestimonial>
                </SwiperSlide>

                <SwiperSlide>
                  <ServiceTestimonial
                    testi_img_num="female"
                    title="Customer"
                    name="Kalpana"
                    msg="Great service. I got my flat deep cleaned by Fluoresen. They did a wonderful job and have definitely exceed my expectations. The staff was very polite. They best thing i liked about them is that they made sure I am happy and satisfied with the work before leaving. I highly recommend fluoresen home cleaning..!"
                  ></ServiceTestimonial>
                </SwiperSlide>

                <SwiperSlide>
                  <ServiceTestimonial
                    testi_img_num="male"
                    title="Customer"
                    name="Sathish kumar"
                    msg="Loved their service!!! They provided us their deep cleaning service  and I was shocked by the results, they changed the complete look of my house and made it  sparkling cleaning and immaculate. Their workers were very professional and cooperative throughout. Saifished with their services, would highly recommend fluoresen services"
                  ></ServiceTestimonial>
                </SwiperSlide>
              </Swiper>

              <div className="swiper-pagination-testimonial slide-dots "></div>
            </div>
          </div>
          <div className="tp-testimonial-two-form tp-testimonial-two-form-four pt-120 ser_form_bg z-index">
            <form
              ref={form}
              method="post"
              onSubmit={OnsubmitEnquiry}
              className="text-start tp-testimonial-two-form-wrapper"
            >
              <h3 className="tp-testimonial-form-title">
                <span>Free</span> Estimate
              </h3>
              <p className="mb-30">
                Free know your project estimate and recent offer
              </p>
              <div className="input-field mb-15">
                <input
                  required
                  name="fname"
                  type="text"
                  placeholder="Your Name"
                />
              </div>
              <div className="input-field mb-15">
                <input
                  required
                  name="phone"
                  type="number"
                  placeholder="Phone No"
                  onChange={OnChanges}
                />
              </div>
              <div className="input-field select-field-arrow mb-15">
                <select name="service" required>
                  <option value="">Choose Subject</option>
                  <option value="House Cleaning">House Cleaning</option>
                  <option value="Office Cleaning">Office Cleaning</option>
                  <option value="Kitchen Cleaning">Kitchen Cleaning</option>
                  <option value="Club Cleaning">Club Cleaning</option>
                  <option value="Airless Spray Painting">
                    Airless Spray Painting
                  </option>
                  <option value="Water Tank Cleaning">
                    Water Tank Cleaning{" "}
                  </option>
                  <option value="Deep Toilet Cleaning">
                    Deep Toilet Cleaning
                  </option>
                  <option value="A Termite Service"> A Termite Service</option>
                  <option value="Carpet Cleaning"> Carpet Cleaning</option>
                </select>
              </div>
              <div className="input-field mb-15">
                <textarea
                  required
                  name="message"
                  placeholder="Write Message"
                ></textarea>
              </div>
              <div className="input-field">
                <button type="submit" className="yellow-btn">
                  Free Estimate
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesTestimonials;
